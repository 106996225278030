<template>
  <div class="pl-16 pr-2">
    <v-card :min-height="windowH" class="mx-auto mt-3 mb-3 bgTransparente" max-width="calc(100% - 10px)" elevation="0">
      <v-card-title class="ml-15 pt-8 pl-0 black--text">Customizar login de ERP</v-card-title>

      <v-row align="start" class="px-13 pb-5">
        <v-col cols="12" lg="3" md="4" sm="6" xs="12" v-for="(provider, i) in provaiders" :key="i">
          <v-card class="pa-3 mx-auto bgContract" max-width="320" tile>
            <v-card :disabled="provider.in_abranet" :color="provider.in_abranet ? 'grey' :
                provider.background_color
                  ? provider.background_color
                  : '#2a2d33'
              ">
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="abranet" v-if="provider.in_abranet">
                    <v-img class="mb-4" contain max-width="100" height="45px" position="center" :src="null"></v-img>
                  </div>
                  <div v-if="!provider.in_abranet">
                    <v-img v-if="provider.logo" class="mb-4" contain max-width="100" height="45px" position="center"
                      :src="provider.logo"></v-img>
                    <v-btn v-else style="font-size: 16px" plain class="mt-2 ml-0 mb-6 pl-2" :color="provider.text_color ? provider.text_color : '#FFFFFFFF'
                      ">
                      <v-icon size="29" dark left> copyright</v-icon>
                      SEM LOGOTIPO
                    </v-btn>
                  </div>
                  <v-list-item-title :style="`color:${provider.text_color ? provider.text_color : '#FFFFFFFF'
                    };`">{{ provider.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider :dark="lightOrDark(provider.background_color)" class="mb-1"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="toggleDialogLoginErp(provider)" icon :color="provider.text_color ? provider.text_color : '#FFFFFFFF'
                      " v-bind="attrs" v-on="on">
                      <v-icon>{{ !provider.in_abranet ? 'edit' : 'edit_off' }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar Tela de Login</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <PreviewLoginErp :getProviders="getProviders" :partner="partner" :dialogLoginErp="dialogLoginErp"
      :toggleDialogLoginErp="toggleDialogLoginErp" />
  </div>
</template>

<script>
import PreviewLoginErp from "./PreviewLoginErp.vue";
export default {
  components: {
    PreviewLoginErp,
  },
  computed: {
    responsiveSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  data() {
    return {
      windowH: "580",
      abranetLogo: require("@/assets/abranet.jpg"),
      darkTheme: true,
      partner: null,
      provaiders: [],
      dialogLoginErp: false,
    };
  },
  beforeMount() {
    this.getHeightPage();
  },
  methods: {
    lightOrDark(color) {
      if (!color) {
        color = "#2a2d33";
      }
      // Variables for red, green, blue values
      var r, g, b, hsp;

      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      if (hsp > 127.5) {
        return false;
      } else {
        return true;
      }
    },
    toggleDialogLoginErp(provider) {
      this.dialogLoginErp = !this.dialogLoginErp;
      if (this.dialogLoginErp) {
        this.partner = provider;
      } else {
        this.partner = null;
      }
    },
    getProviders() {
      var obj = {
        url: "/api/partner/get-all",
        query: null,
        method: "get",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        // console.log(response);
        // this.provaiders = [...response.data.filter((partner) => !partner.erp)];
        this.provaiders = [...response.data];
        // this.provaiders = [...response.data];
      });
    },
    getHeightPage() {
      this.windowH = window.innerHeight - 125;
    },
  },
  created() {
    window.addEventListener("resize", this.getHeightPage);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeightPage);
  },
  mounted() {
    this.getProviders();
  },
};
</script>
<style>
.textColor {
  color: var("provider.text_color");
}
</style>