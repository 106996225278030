<template>
  <v-dialog v-model="dialogLoginErp" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card elevation="0">
      <v-img src="../assets/tv01-black.jpg">
        <v-toolbar dark tile flat color="transparent">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="toggleDialogLoginErp" color="error" fab x-small dark v-bind="attrs" v-on="on">
                <v-icon>close</v-icon>
              </v-btn>
            </template>
            <span>Voltar</span>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-menu v-model="fieldsEditTheme" :close-on-content-click="false" :nudge-width="200" offset-x class="pr-5">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="teal" fab small dark v-bind="attrs" v-on="on">
                <v-icon>settings</v-icon>
              </v-btn>
            </template>
            <v-card class="mr-15 px-2 py-5">
              <v-card-title class="pt-0 text-h6 black--text">Editar template</v-card-title>
              <v-divider></v-divider>
              <v-form ref="saveForm">
                <v-list>
                  <v-list-item class="mt-5">
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="customizeErp.background_color" label="Cor principal" prepend-icon="palette"
                          v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-color-picker mode="hexa" flat width="280px" dot-size="25"
                        v-model="customizeErp.background_color">
                      </v-color-picker>
                    </v-menu>
                    <v-spacer></v-spacer>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item class="mt-5">
                    <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="customizeErp.text_color" label="Cor secundária" prepend-icon="palette"
                          v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-color-picker flat width="280px" dot-size="25" v-model="customizeErp.text_color" no-title
                        mode="hexa">
                      </v-color-picker>
                    </v-menu>
                  </v-list-item>
                  <v-divider class=""></v-divider>
                  <v-list-item class="mt-5">
                    <v-text-field prepend-icon="palette" v-model="customizeErp.route" label="Nome do IDP"></v-text-field>
                  </v-list-item>

                  <v-divider class="mb-2"></v-divider>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-file-input :full-width="false" @change="uploadImage" chips v-model="customizeErp.logo"
                          show-size="" class="ma-5" accept="image/png, image/jpeg, image/bmp"
                          placeholder="Escolha um arquivo" label="Logotipo"></v-file-input>
                      </div>
                    </template>
                    <span>Tamanho máximo 2 megas</span>
                  </v-tooltip>
                  <v-row>
                    <v-col cols="12" xs="12" class="d-flex justify-center">
                      <v-btn outlined rounded color="teal" dark @click="confirmSaveCustomisation">
                        Salvar
                      </v-btn>
                    </v-col>
                    <!-- <v-col cols="12" md="6" class="d-flex justify-center">
                      <v-btn plain color="teal" dark @click="saveCustomisation"
                        >Salvar</v-btn
                      >
                    </v-col> -->
                  </v-row>
                </v-list>
              </v-form>
            </v-card>
          </v-menu>
        </v-toolbar>
        <section class="section-container">
          <v-row class="signin erpLogin">
            <v-col cols="12" sm="12" md="12" :style="dataTheme.bg" class="roundedWindow">
              <v-row class="">
                <v-col cols="12" class="mt-3 d-flex justify-center">
                  <!-- <img v-bind:src="'data:image/jpeg;base64,'+imageBytes" /> -->
                  <v-img contain :src="cover(imageSrc)" max-width="230" max-height="90"></v-img>
                  <!-- src="../assets/logo-durand-branco.png" -->
                </v-col>
              </v-row>
              <h6 class="textColor mt-5 text-h6 text-center font-weight-bold"></h6>
              <!-- :style="colors.textColor" -->
              <h5 :style="dataTheme.txt" class="textColor mb-10 mt-12 text-h5 text-center">
                Bem vindo!
              </h5>
              <!-- <h3 class="textColor">Área administrativa</h3> -->

              <v-form ref="loginForm" v-model="valid">
                <v-text-field disabled :dark="darkTheme" label="Email"></v-text-field>

                <v-text-field disabled :dark="darkTheme" label="Senha" :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPass = !showPass" :type="showPass ? 'text' : 'password'"></v-text-field>

                <div class="text-center mt-3">
                  <v-btn class="signin-btn" rounded block :color="customizeErp.text_color" :style="dataTheme.btnColor">
                    Entrar
                  </v-btn>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </section>
      </v-img>
    </v-card>
  </v-dialog>
</template>
<script>
const defaultColors = {
  bg: "background-color: #2a2d33",
  txt: "color: #FFFFFFFF",
  btnColor: "color: #2a2d33",
};
export default {
  methods: {
    confirmSaveCustomisation() {
      console.log('debug')
      this.fieldsEditTheme = false
      if (!this.askIDP && this.customizeErp.route) {
        this.saveCustomisation(true)
        return
      }
      if (!this.askIDP && !this.customizeErp.route) {
        this.saveCustomisation(false)
        return
      }
      let obj = {
        message: "Deseja gerar novas credenciais para o IDP?",
        title: "Gerar novas credenciais",
        callback: (_) => {
          this.saveCustomisation(true)
        },
        cancelCallback: (_) => {
          this.saveCustomisation(false)

        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    saveCustomisation(newCredentials) {
      if (!this.$refs.saveForm.validate()) {
        return;
      }
      this.customizeErp.logo = this.imageSrc ? this.imageSrc : null;
      let query = { ...this.customizeErp };
      query.partner_id = this.partner.id;
      query.new_credentials = newCredentials
      var obj = {
        url: "/api/partner/edit-preferences",
        query: query,
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        this.toggleDialogLoginErp();
        this.getProviders();
      });
    },

    cover(url) {
      if (url !== "") {
        // You can check any matching expression.
        try {
          url = require(this.imageSrc);
        } catch (e) {
          // url = require("/assets/img/default.jpg"); // I used a default image.
        }
      } else {
        url = require("../assets/logo-durand-branco.png");
      }
      return url;
    },
    uploadImage: function () {
      let _this = this;
      var file = document.querySelector("input[type=file]").files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        _this.imageSrc = e.target.result;
      };
      reader.onerror = function (error) {
        alert(error);
      };
      reader.readAsDataURL(file);
    },
    setColor(type) {
      if (type == "bg") {
        this.dataTheme.bg = `background-color:${this.customizeErp.background_color}`;
        this.dataTheme.btnColor = `color:${this.customizeErp.background_color}`;
      } else {
        this.dataTheme.txt = `color:${this.customizeErp.text_color}`;
      }
      this.lightOrDark(
        this.customizeErp.background_color
          ? this.customizeErp.background_color
          : "#2a2d33"
      );
    },
    lightOrDark(color) {
      // Variables for red, green, blue values
      var r, g, b, hsp;

      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      if (hsp > 127.5) {
        this.darkTheme = false;
        return "light";
      } else {
        this.darkTheme = true;
        return "dark";
      }
    },
  },
  components: {},
  data: () => ({
    valid: true,
    fieldsEditTheme: false,
    askIDP: false,
    imageSrc: "",
    imgBase64: "",
    darkTheme: true,
    dataTheme: {
      ...defaultColors,
    },
    customizeErp: {
      route: "",
      background_color: "#2a2d33",
      text_color: "#FFFFFFFF",
      logo: null,
    },
    menu: false,
    menu2: false,
    dialog: false,
    showPass: false,
  }),

  computed: {
    responsiveSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },

  mounted() { },
  watch: {
    dialogLoginErp(v) {
      if (v) {
        // console.log("ok", this.partner.route);
        if (this.partner.route) { this.askIDP = true }
        this.dataTheme.bg = `background-color: ${this.partner.background_color}`;
        this.dataTheme.txt = `color: ${this.partner.text_color}`;
        this.dataTheme.btnColor = `color: ${this.partner.background_color}`;
        this.customizeErp.route = this.partner.route;
        this.customizeErp.text_color = this.partner.text_color
          ? this.partner.text_color
          : "#FFFFFFFF";
        this.customizeErp.background_color = this.partner.background_color
          ? this.partner.background_color
          : "#2a2d33";
        this.imageSrc = this.partner.logo ? this.partner.logo : null;
      } else {
        this.dataTheme = { ...defaultColors };
        this.askIDP = false
        this.customizeErp.logo = null;
      }
    },
    customizeErp: {
      handler(val) {
        this.setColor("bg");
        this.setColor("btnColor");
      },
      deep: true,
    },
  },
  props: {
    dialogLoginErp: Boolean,
    toggleDialogLoginErp: Function,
    partner: Object,
    getProviders: Function,
  },
};
</script>

<style>
.section-container .signin.erpLogin {
  min-height: 545px;
  max-width: 500px;
}

.roundedWindow {
  border-radius: 25px;
  /* background-color: #2a2d33; */
  padding: 20px;
  width: 200px;
  height: 440px;
}

.textColor {
  color: azure;
}
</style>