var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogLoginErp),callback:function ($$v) {_vm.dialogLoginErp=$$v},expression:"dialogLoginErp"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-img',{attrs:{"src":require("../assets/tv01-black.jpg")}},[_c('v-toolbar',{attrs:{"dark":"","tile":"","flat":"","color":"transparent"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","fab":"","x-small":"","dark":""},on:{"click":_vm.toggleDialogLoginErp}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("close")])],1)]}}])},[_c('span',[_vm._v("Voltar")])]),_c('v-spacer'),_c('v-menu',{staticClass:"pr-5",attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"teal","fab":"","small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("settings")])],1)]}}]),model:{value:(_vm.fieldsEditTheme),callback:function ($$v) {_vm.fieldsEditTheme=$$v},expression:"fieldsEditTheme"}},[_c('v-card',{staticClass:"mr-15 px-2 py-5"},[_c('v-card-title',{staticClass:"pt-0 text-h6 black--text"},[_vm._v("Editar template")]),_c('v-divider'),_c('v-form',{ref:"saveForm"},[_c('v-list',[_c('v-list-item',{staticClass:"mt-5"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Cor principal","prepend-icon":"palette"},model:{value:(_vm.customizeErp.background_color),callback:function ($$v) {_vm.$set(_vm.customizeErp, "background_color", $$v)},expression:"customizeErp.background_color"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-color-picker',{attrs:{"mode":"hexa","flat":"","width":"280px","dot-size":"25"},model:{value:(_vm.customizeErp.background_color),callback:function ($$v) {_vm.$set(_vm.customizeErp, "background_color", $$v)},expression:"customizeErp.background_color"}})],1),_c('v-spacer')],1),_c('v-divider'),_c('v-list-item',{staticClass:"mt-5"},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Cor secundária","prepend-icon":"palette"},model:{value:(_vm.customizeErp.text_color),callback:function ($$v) {_vm.$set(_vm.customizeErp, "text_color", $$v)},expression:"customizeErp.text_color"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-color-picker',{attrs:{"flat":"","width":"280px","dot-size":"25","no-title":"","mode":"hexa"},model:{value:(_vm.customizeErp.text_color),callback:function ($$v) {_vm.$set(_vm.customizeErp, "text_color", $$v)},expression:"customizeErp.text_color"}})],1)],1),_c('v-divider',{}),_c('v-list-item',{staticClass:"mt-5"},[_c('v-text-field',{attrs:{"prepend-icon":"palette","label":"Nome do IDP"},model:{value:(_vm.customizeErp.route),callback:function ($$v) {_vm.$set(_vm.customizeErp, "route", $$v)},expression:"customizeErp.route"}})],1),_c('v-divider',{staticClass:"mb-2"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-file-input',{staticClass:"ma-5",attrs:{"full-width":false,"chips":"","show-size":"","accept":"image/png, image/jpeg, image/bmp","placeholder":"Escolha um arquivo","label":"Logotipo"},on:{"change":_vm.uploadImage},model:{value:(_vm.customizeErp.logo),callback:function ($$v) {_vm.$set(_vm.customizeErp, "logo", $$v)},expression:"customizeErp.logo"}})],1)]}}])},[_c('span',[_vm._v("Tamanho máximo 2 megas")])]),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","xs":"12"}},[_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"teal","dark":""},on:{"click":_vm.confirmSaveCustomisation}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1)],1)],1),_c('section',{staticClass:"section-container"},[_c('v-row',{staticClass:"signin erpLogin"},[_c('v-col',{staticClass:"roundedWindow",style:(_vm.dataTheme.bg),attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-row',{},[_c('v-col',{staticClass:"mt-3 d-flex justify-center",attrs:{"cols":"12"}},[_c('v-img',{attrs:{"contain":"","src":_vm.cover(_vm.imageSrc),"max-width":"230","max-height":"90"}})],1)],1),_c('h6',{staticClass:"textColor mt-5 text-h6 text-center font-weight-bold"}),_c('h5',{staticClass:"textColor mb-10 mt-12 text-h5 text-center",style:(_vm.dataTheme.txt)},[_vm._v(" Bem vindo! ")]),_c('v-form',{ref:"loginForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"disabled":"","dark":_vm.darkTheme,"label":"Email"}}),_c('v-text-field',{attrs:{"disabled":"","dark":_vm.darkTheme,"label":"Senha","append-icon":_vm.showPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPass ? 'text' : 'password'},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}}}),_c('div',{staticClass:"text-center mt-3"},[_c('v-btn',{staticClass:"signin-btn",style:(_vm.dataTheme.btnColor),attrs:{"rounded":"","block":"","color":_vm.customizeErp.text_color}},[_vm._v(" Entrar ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }